import Vue from 'vue'
import App from './App.vue'
import router from './router'
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import animate from 'animate.css'
import axios from 'axios'
Vue.prototype.$axios = axios //将axios绑定到vue的原型上

// Vue.use(animate);
Vue.use(ElementUI);
import VueAwesomeSwiper from 'vue-awesome-swiper'
import 'swiper/dist/css/swiper.css'


import "./assets/css/css.css";
import "./assets/fonts/iconfont.css";


Vue.config.productionTip = false
Vue.use(VueAwesomeSwiper)
new Vue({
	router,
	render: h => h(App),
}).$mount('#app')
